<style scoped>
#app .theme--light.v-data-table >
.v-data-table__wrapper > table > tbody > td{
border-bottom: 1px solid #ADADAD !important;
border-top: 1px solid #ADADAD !important;
}
#app .v-application .v-data-table td {
    padding: 0rem 1rem !important;
}
</style>
<style>
/* #app .v-application .v-data-table td {
    padding: 0.75rem 2rem !important;
} */
.tdparametro span{
  padding-left: 0px !important;
}

#app .v-data-table th {
    font-weight: bold !important;
}
</style>
<template>
<!--eslint-disable-->
  <v-row justify="center">
    <v-dialog
    v-model="dialog"
    persistent
    max-width="400px"
    >
      <v-snackbar v-model="snack.snackbar" :vertical="snack.vertical"
      :color="snack.color" :right="true" :top="true">
        <div v-if="snack.color == 'red'">
          {{ snack.txt }}
        </div>
        <div v-else class="c-snackbar__active">
            <svg class="mr-4" width="18.021" height="17.778" viewBox="0 0 18.021 17.778">
                <g id="check-circle" transform="translate(-1.039 -1.074)">
                <path id="Trazado_1" data-name="Trazado 1" d="M18,9.262V10a8,8,0,1,1-4.744-7.312" transform="translate(0 0)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Trazado_2" data-name="Trazado 2" d="M19.13,4l-7.793,7.8L9,9.463" transform="translate(-1.131 -0.443)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
            </svg>
            {{ snack.txt }}
        </div>

        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                :color="snack.colorbtn"
                @click="snack.snackbar = false"
            >{{$t('ensayos.cerrar')}}</v-btn>
        </template>
    </v-snackbar>
      <v-card>
        <v-card-title style="padding-bottom: 0 !important;">
          <p class="bold mb-0 txt-enasyo">{{ $t('ensayos.txt_title') }}</p>
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
            <div class="d-flex align-items-center">
                <v-text-field
                  :placeholder="$t('ensayos.add_param')"
                  v-model="parametronombre"
                  dense
                  outlined
                >
                </v-text-field>
                <a
                  class="ml-3 mr-2 d-flex align-items-center text-active fs16"
                  @click="guardaparametro()"
                >
                  <svg  class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                    <g id="plus-circle" transform="translate(-0.869 -0.869)">
                      <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </svg>
                  {{ $t('ensayos.Addparameter') }}
                </a>             
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card class="c-parametros">
                        <v-data-table
                        dense
                        fixed-header
                        height="250px"
                        :headers="headers"
                        :items="data_ensayo.parametros"
                        :search="search"
                        class="table-param"
                        disable-pagination
                        hide-default-footer
                        >
                        <template v-slot:item.nombre="{ item }" >
                          <div class="d-flex field-parametro">
                            <v-text-field
                            v-model="item.nombre"
                            dense
                            outlined
                            class="field-parametro"
                            ></v-text-field>
                            <img class="ml-3 mr-7r" width="17" @click="DeleteParam(item)" src="/img/eliminar.svg">
                          </div> 
                        </template>

                        <!-- <template v-slot:item.cod_pat="{ item  }" >
                            <img width="17" class="ml-5 mr-4" @click="updateName(item)" src="/img/editar.svg" >
                            
                        </template> -->

                        
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="dialog = false"
                  class="c-btn"
                  color="#007AFF"
                  outlined
                >
                 {{ $t('ensayos.cancelar') }}
                </v-btn>
                <v-btn
                    class="ml-5 c-btn c-btn--blue bold"
                    outlined
                    @click="dialog = false"
                >
                    {{ $t('ensayos.guardar') }}
                </v-btn>
                </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';

export default {
  data: () => ({
    dialog: false,
    search: '',
    parametronombre: '',
    nivelSelect: null,
    nivelParametros: [
      {
        id: 1,
        nombre: 'Patogeno alto',
        nivel: 1,
      },
      {
        id: 2,
        nombre: 'Patogeno bajo',
        nivel: 2,
      },
      {
        id: 3,
        nombre: 'Indicador',
        nivel: 3,
      },
    ],
    headers: [
      /*
      {
        // text: i18n.t('ensayos.you_param'),
        text: null,
        class: 'thparametro',
        align: 'start',
        value: 'nombre',
        sortable: false,
      },
      */
      // { text: 'Nivel', value: 'especie' },
      // { text: '', class: 'td-btn', value: 'cod_pat' },
    ],
    snack: {
      snackbar: false,
      txt: 'null',
      color: 'red',
      error: false,
      x: null,
      y: 'top',
      colorbtn: '#fff',
    },
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  methods: {
    ...mapMutations('Ensayos', ['setMix']),
    openmodal() {
      this.dialog = true;
      // this.headers.text = i18n.t('ensayos.you_param');
      this.headers = [
        {
          text: i18n.t('ensayos.you_param'),
          class: 'thparametro',
          align: 'start',
          value: 'nombre',
          sortable: false,
        },
      ];
    },
    async guardaparametro() {
      if (this.parametronombre !== '') {
        // const dateact = new Date();
        // const seconds = dateact.getTime();
        const crearParam = {
          nombre: this.parametronombre,
          nomcort: `${this.parametronombre}`,
          clasificacion: 2,
          especie: this.nivelSelect,
          unidad: null,
          color: '#795548',
          nombre_es: this.parametronombre,
        };
        const creaparam = await Service.apiToken('POST', 'crea-parametro', this.$ls.storage.token, crearParam);
        this.snack.snackbar = true;
        this.snack.color = '#F5F5F5';
        this.snack.colorbtn = '#007AFF';
        this.snack.txt = i18n.t('ensayos.save_param');
        this.data_ensayo.parametros.push(creaparam.data);
        this.parametronombre = '';
        this.nivelSelect = null;
      }
    },
    async DeleteParam(item) {
      // peticion para eliminar el parametro en ensayos y en patogeno
      await Service.apiToken('POST', 'delete-parametro', this.$ls.storage.token, item);
      const paramnew = [];
      this.snack.snackbar = true;
      this.snack.color = 'red';
      this.snack.colorbtn = '#fff';
      this.snack.txt = i18n.t('ensayos.delete_param');
      this.data_ensayo.parametros.forEach((element) => {
        /* eslint-disable no-param-reassign */
        if (element.cod_pat === item.cod_pat) {
          element.activo = 0;
        } else {
          paramnew.push(element);
        }
      });

      this.data_ensayo.parametros = paramnew;
    },
    async updateName(item) {
      await Service.apiToken('POST', 'update-parametro', this.$ls.storage.token, item);
      this.snack.snackbar = true;
      this.snack.txt = i18n.t('ensayos.update_succes');
    },
  },
};
</script>
